// Noto Sans - exported from google fonts
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(./fonts/noto-sans.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Material Icons
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;

  src: local("Material Icons"), local("assets/fonts/MaterialIcons-Regular"),
    url(./fonts/MaterialIcons-Regular.woff2) format("woff2");
  // url(./fonts/MaterialIcons-Regular.woff) format('woff'),
  // url(./fonts/MaterialIcons-Regular.ttf) format('truetype');

  // src: url(./fonts/MaterialIcons-Regular.eot);
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
