/* You can add global styles to this file, and also import other style files */
@import "./theme/sami-theme.scss";

@import "./theme/fonts";
@import "./theme/overrides";
@import "./theme/typography";

html,
body {
  height: 100%;
  // attempt fix for ios safe view areas.. possibly covered in capacitor.config.json contentInset
  // appears too big gap at status-bar - now added as padding to mat-toolbar instead
  // padding: env(safe-area-inset-top) env(safe-area-inset-right)
  //   env(safe-area-inset-bottom) env(safe-area-inset-left);
}
body {
  margin: 0;
}
/* Markdown child content styling */
table,
th,
td {
  border: 1px solid black;
  padding: 5px;
}
table {
  border-collapse: collapse;
}
th {
  background: #494848;
  color: white;
}
// add pseudo element to ensure empty cells fill minimum height
td:first-child::after {
  content: "";
  display: inline-block;
  vertical-align: top;
  min-height: 1em;
}
td {
  min-width: 50px;
}
#markdownStyle {
  img {
    max-width: 500px;
  }
  // avoid code blocks adding horizontal scroll space
  code {
    white-space: pre-line;
  }
  @media only screen and (max-width: 500px) {
    img {
      max-width: 100%;
    }
  }
}
.page {
  padding: 1em;
}
